import { Route, Routes } from "react-router-dom";
import "swiper/css/bundle";
import React, { useEffect, useState , useMemo } from "react";
import AmericanTourister from "../pages/SupportPages/AmericanTourister";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Homepage from "../pages/Homepage";
import InsuranceMethod from "../pages/SupportPages/Insurance";
import InvoiceInfo from "../pages/InvoiceInfo";
import OrderMethod from "../pages/SupportPages/OrderMethod";
import PaymentMethod from "../pages/SupportPages/PaymentMethod";
import Post from "../pages/Post";
import Posts from "../pages/SupportPages/Posts";
import PrecheckPolicy from "../pages/SupportPages/PrecheckPolicy";
import ProductInfo from "../pages/ProductInfo";
import ProductList from "../pages/ProductList";
import ReceiveMethod from "../pages/SupportPages/ReceiveMethod";
import ReportPolicy from "../pages/SupportPages/ReportPolicy";
import ReturnMethod from "../pages/SupportPages/ReturnMethod";
import SecurityMethod from "../pages/SupportPages/SecurityMethod";
import Sitemap from "../components/Sitemap/Sitemap";
import Stores from "../pages/Stores";
import TermsConditions from "../pages/SupportPages/TermsConditions";
import Api from "../components/api/apis";
import ShopByColor from "../pages/ShopByColor";

const getParam = (p) => {
  const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

const getExpiryRecord = (value) => {
  const expiryPeriod = 8 * 60 * 60 * 1000;
  const expiryDate = new Date().getTime() + expiryPeriod;
  return {
    value: value,
    expiryDate: expiryDate
  };
};

const addGclid = () => {
  //{"value":"CjwKCAiA0bWvBhBjEiwAtEsoW6Ly8R6_Vdjye5HMtSvZIUutqB1TowOLS-zptDe0JInwOSxzOgHFExoC1aAQAvD_BwE","expiryDate":1710160733963}
  const localGclid = JSON.parse(localStorage.getItem('gclid'))
  // Kiểm tra nếu localGclid không phải là một object hoặc không có trường value hoặc không có trường gclid là một Date
  if (
    typeof localGclid !== 'object' || // Kiểm tra nếu không phải là object
    localGclid === null || // Kiểm tra nếu không có giá trị
    typeof (localGclid?.value ?? null) !== 'string' // Kiểm tra nếu trường value không phải là string
    ) {
    // Xóa gclid từ localStorage
    localStorage.removeItem('gclid');
  }

  const gclidParam = getParam('gclid');
  let gclidRecord = null;

  if (gclidParam ) {
    gclidRecord = getExpiryRecord(gclidParam);
    localStorage.setItem('gclid', JSON.stringify(gclidRecord));
  }
};

const addFbclid = () => {
  //{"value":"CjwKCAiA0bWvBhBjEiwAtEsoW6Ly8R6_Vdjye5HMtSvZIUutqB1TowOLS-zptDe0JInwOSxzOgHFExoC1aAQAvD_BwE","expiryDate":1710160733963}
  const localGclid = JSON.parse(localStorage.getItem('fbclid'))
  // Kiểm tra nếu localGclid không phải là một object hoặc không có trường value hoặc không có trường fbclid là một Date
  if (
    typeof localGclid !== 'object' || // Kiểm tra nếu không phải là object
    localGclid === null || // Kiểm tra nếu không có giá trị
    typeof (localGclid?.value ?? null) !== 'string' // Kiểm tra nếu trường value không phải là string
    ) {
    // Xóa fbclid từ localStorage
    localStorage.removeItem('fbclid');
  }

  const fbclidParam = getParam('fbclid');
  let fbclidRecord = null;

  if (fbclidParam ) {
    fbclidRecord = getExpiryRecord(fbclidParam);
    localStorage.setItem('fbclid', JSON.stringify(fbclidRecord));
  }
};


function RoutePath() {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  // kich hoat su kien google, fb
  const [cartEvent, setCartEvent] = useState(false);
  const [cartChange, setCartChange] = useState(1);
  const [channel, setChannel] = useState({});
  const [discountList, setDiscountList] = useState({});

  useEffect(() => {
    fetchData();
    
    addGclid();

    addFbclid();
  }, []);
  
  const fetchData = async () => {
    try {
      const [channelRes, discountListRes] = await Promise.all([
        fetch(`${apiURL}/channel`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ name: "American Tourister" })
        }),
        Api.getDiscount()
      ]);
  
      if (channelRes.ok) {
        const channelData = await channelRes.json();
        setChannel(channelData.success ? channelData.channel : {});
      }
  
      setDiscountList(discountListRes);
    } catch (error) {
      console.log(error);
    }
  };
  
  return (
    <React.Fragment>
      <Header setCartChange={setCartChange} cartChange={cartChange} discountList = {discountList} cartEvent = {cartEvent} setCartEvent = {setCartEvent}/>
      <Routes>
        <Route
          index
          element={
            <div>
              <Homepage channel={channel} discountList = {discountList} />
            </div>
          }
        />
        <Route
          path="/:category/:type/:detail"
          
          element={
            <div>
              <ProductList channel={channel} cartEvent = {cartEvent} setCartEvent = {setCartEvent} discountList = {discountList} setCartChange={setCartChange} title="Danh sách sản phẩm - American Tourister Việt Nam" />
            </div>
          }
        />
        <Route
          path="/special-offers"
          
          element={
            <div>
              <ProductList channel={channel} cartEvent = {cartEvent} setCartEvent = {setCartEvent} discountList = {discountList} setCartChange={setCartChange} title="Special Offers" />
            </div>
          }
        />
        <Route
          path="/for-her"
          
          element={
            <div>
              <ProductList channel={channel} cartEvent = {cartEvent} setCartEvent = {setCartEvent} discountList = {discountList} setCartChange={setCartChange} title="For Her" />
            </div>
          }
        />
        <Route
          path="/p/:productline"
          element={
            <div>
              <ProductInfo setCartChange={setCartChange} discountList = {discountList}  cartEvent = {cartEvent} setCartEvent = {setCartEvent}/>
            </div>
          }
        />
        <Route
          path="/cart"
          
          element={
            <div>
              <Cart setCartChange={setCartChange} discountList = {discountList} title="Giỏ hàng của bạn - American Tourister Việt Nam" />
            </div>
          }
        />
        <Route
          path="/shop-by-color"
          
          element={
            <div>
              <ShopByColor title="Shop By Color" discountList = {discountList} />
            </div>
          }
        />
        <Route
          path="/checkout"
          
          element={
            <div>
              <Checkout channel={channel} discountList = {discountList} title="Thanh toán - American Tourister Việt Nam" />
            </div>
          }
        />
        <Route
          path="/stores"
          
          element={
            <div>
              <Stores title="Hệ thống cửa hàng - American Tourister Việt Nam" />
            </div>
          }
        />
        <Route
          path="/support/terms-and-conditions"
          
          element={
            <div>
              <TermsConditions title="Điều khoản & Điều kiện - American Tourister Việt Nam" />
            </div>
          }
        />
        <Route
          path="/support/oder-method"
          
          element={
            <div>
              <OrderMethod title="Phương thức đặt hàng - American Tourister Việt Nam"/>
            </div>
          }
        />
        <Route
          path="/support/receive-method"
          
          element={
            <div>
              <ReceiveMethod title="Phương thức giao nhận - American Tourister Việt Nam"/>
            </div>
          }
        />
        <Route
          path="/support/payment-method"
          
          element={
            <div>
              <PaymentMethod title="Phương thức thanh toán - American Tourister Việt Nam"/>
            </div>
          }
        />
        <Route
          path="/support/precheck-policy"
          
          element={
            <div>
              <PrecheckPolicy title="Chính sách kiểm hàng - American Tourister Việt Nam"/>
            </div>
          }
        />
        <Route
          path="/support/exchange-and-return-policy"
          
          element={
            <div>
              <ReturnMethod title="Chính sách đổi trả hàng - American Tourister Việt Nam"/>
            </div>
          }
        />
        <Route
          path="/support/report-policy"
          
          element={
            <div>
              <ReportPolicy title="Chính sách xử lý khiếu nại - American Tourister Việt Nam"/>
            </div>
          }
        />
        <Route
          path="/support/security-policy"
          
          element={
            <div>
              <SecurityMethod title="Chính sách bảo mật - American Tourister Việt Nam"/>
            </div>
          }
        />
        <Route
          path="/support/insurance-policy"
          
          element={
            <div>
              <InsuranceMethod title="Chính sách bảo hành - American Tourister Việt Nam"/>
            </div>
          }
        />
        <Route
          path="/about-us"
          
          element={
            <div>
              <AmericanTourister title="Thông tin chúng tôi - American Tourister Việt Nam"/>
            </div>
          }
        />
        <Route
          path="/order/:id"
          
          element={
            <div>
              <InvoiceInfo title="Thông tin đơn hàng - American Tourister Việt Nam"/>
            </div>
          }
        />
        <Route
          path="/posts"
          
          element={
            <div>
              <Posts title = "Bài viết"/>
            </div>
          }
        />
        <Route
          path="/post/:name"
          
          element={
            <div>
              <Post/>
            </div>
          }
        />
        <Route
          path="/sitemap.xml"
          
          element={
            <div>
              <Sitemap title = "sitemap.xml"/>
            </div>
          }
        />
        <Route
          path="/sitemap_products_1.xml"
          
          element={
            <div>
              <Sitemap title = "sitemap_products_1.xml"/>
            </div>
          }
        />
        <Route
          path="/sitemap_blogs_1.xml"
          
          element={
            <div>
              <Sitemap title = "sitemap_blogs_1.xml"/>
            </div>
          }
        />
        <Route
          path="/sitemap_pages_1.xml"
          
          element={
            <div>
              <Sitemap title = "sitemap_pages_1.xml"/>
            </div>
          }
        />
        <Route
          path="/sitemap_collections_1.xml"
          
          element={
            <div>
              <Sitemap title = "sitemap_collections_1.xml"/>
            </div>
          }
        />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default RoutePath;
